/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useEffect, useMemo, useState } from "react";
import { When } from "react-if";
import { Box } from "@nordcloud/gnui";
import { usePeopleQuery, useThresholdsQuery } from "~/generated/graphql";
import {
  BreadcrumbsBox,
  BrickLoader,
  DataDisplayWrapper,
  NoData,
  Pagination,
  ReactTableV2,
  UniversalWrap,
} from "~/components";
import { useCurrency, useQueryState } from "~/hooks";
import { isNil, isNotNil } from "~/tools";
import { ThresholdsListColumns } from "./ThresholdsListColumns";
import {
  Threshold,
  ThresholdSortField,
  ThresholdSortOrder,
  ThresholdsQueryState,
} from "./types";

export function ThresholdsPage() {
  const { state, updateQueryState } = useQueryState<ThresholdsQueryState>();
  const { column, order, limit, page } = state;

  const {
    data,
    loading: isLoading,
    error,
  } = useThresholdsQuery({
    variables: {
      sorting: {
        field: getColumnEnumValue(column ?? "createdDate"),
        order: order ?? ThresholdSortOrder.Desc,
      },
    },
    skip: false,
  });

  const { currency } = useCurrency();
  const { data: people } = usePeopleQuery();

  const [thresholds, setThresholds] = useState<Threshold[]>([]);

  const breadcrumbLabels = [{ value: "thresholds", label: "Thresholds" }];

  useEffect(() => {
    if (!isLoading && isNil(column) && isNil(order)) {
      updateQueryState({
        column: "createdDate",
        order: ThresholdSortOrder.Desc,
      });
    }
  }, [isLoading, column, order, updateQueryState]);

  useEffect(() => {
    setThresholds(
      data?.thresholds.slice(page * limit, (page + 1) * limit) ?? []
    );
  }, [limit, page, order, data]);

  const thresholdCount = data?.thresholds.length ?? 0;

  const getPersonNameByEmail = useCallback(
    (email: string) => {
      return people?.people?.find((item) => item?.email === email)?.name;
    },
    [people]
  );

  const columns = useMemo(
    () => ThresholdsListColumns({ getPersonNameByEmail, currency }),
    [getPersonNameByEmail, currency]
  );

  const onSort = (sort: { id: string; desc: boolean }) => {
    updateQueryState({
      ...state,
      column: sort.id,
      order: sort.desc ? ThresholdSortOrder.Desc : ThresholdSortOrder.Asc,
      page: 0,
    });
  };

  const defaultSort = {
    id: column ?? "createdDate",
    desc: (isNotNil(order) ? order === ThresholdSortOrder.Desc : false) ?? true,
  };

  return (
    <>
      <BreadcrumbsBox title="Thresholds" labels={breadcrumbLabels} />
      <UniversalWrap
        errorProps={{ error }}
        loaderProps={{
          loading: isLoading,
          Component: BrickLoader,
        }}
      >
        <DataDisplayWrapper
          hasData={thresholdCount > 0}
          renderNoData={(message) => (
            <Box>
              <NoData message={message} />
            </Box>
          )}
        >
          <Box spacing="spacing04">
            <ReactTableV2
              striped
              sort
              columns={columns}
              data={thresholds || []}
              defaultSort={defaultSort}
              onSort={onSort}
            />
            <When condition={thresholdCount > 0}>
              <Pagination count={thresholdCount} />
            </When>
          </Box>
        </DataDisplayWrapper>
      </UniversalWrap>
    </>
  );
}

function getColumnEnumValue(
  column: "amount" | "createdDate" | "name" | (string & {})
) {
  switch (column) {
    case "name":
      return ThresholdSortField.Name;
    case "createdDate":
      return ThresholdSortField.CreatedDate;
    case "amount":
      return ThresholdSortField.Amount;
    default:
      return ThresholdSortField.CreatedDate;
  }
}
