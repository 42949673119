/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export type Threshold = {
  accounts: string[];
  createdDate: string;
  granularity: ThresholdGranularity;
  id: string;
  name: string;
  providers: string[];
  services: string[];
  thresholdAmount: number;
  userRecipients: string[];
};

export enum ThresholdGranularity {
  Daily = "DAILY",
  Monthly = "MONTHLY",
}

export type ThresholdsQueryState = {
  column?: string;
  order?: ThresholdSortOrder;
};

export enum ThresholdSortOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export enum ThresholdSortField {
  Amount = "AMOUNT",
  CreatedDate = "CREATED_DATE",
  Name = "NAME",
}
